import React, { Component } from "react";
//import { Link, navigate } from 'gatsby'
import Header from "./header"
import AboutBanner from "../../images/curbside_about.jpg";

class Aboutpage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Header/>
        <section className="aboutus__section">
          <img src={AboutBanner} alt="" />
          <div className="imgoverlay"></div>
          <div className="abouttext">
            <h1>About Curbside</h1>
            <div className="whitebox">
              <h3>About Curbside</h3>
              <h4>About Curbside</h4>
              <p>
                We understand that debris removal is difficult. We have made it our goal to provide a truly unique experience for construction and demolition debris removal. The debris industry is always changing and we make sure to listen and implement new ways of doing business.
              </p>
              <p>
                Our group of experts are dedicated to solving the pain points throughout the construction and debris removal process. Our human-centric approach and tech-enabled solutions allow for a great client experience.
              </p>
              <h4>Our Story </h4>
              <p>
                Curbside was founded with the mission to bring new ideas to the debris removal space. Our goal each and every 
                day is to work with our clients to provide quality, sustainable and cost effective service while investing in
                a more tech enhanced future. We have built a dedicated and diverse team who will provide the highest level of
                customer service. We will continue to provide the newest and most forward thinking experience so our clients
                can focus on the job at hand. We look forward to working with you.
              </p>
            </div>
          </div>

        </section>
      </div>
    );
  }
}

export default Aboutpage;
